import { datadogRum } from '@datadog/browser-rum';
import { useEmailSending, useSnackBars } from '@ev/eva-container-api';
import { Box, Button, InputAdornment, Stack, TextField } from '@mui/material';
import { EmailTemplate } from 'api/graphql/generated/graphql';
import { useResolveEmailTemplate } from 'api/rest/hooks/useEmail';
import { useSendEmail } from 'api/rest/hooks/useSendEmail';
import { useResolveEmailSignature } from 'api/rest/LeadEngineService';
import { Attachment } from 'components/emails/Attachment';
import { ContactsRemovedAlert } from 'components/emails/ContactsRemovedAlert/ContactsRemovedAlert';
import { EmailInvalidLinksModal } from 'components/emails/EmailInvalidLinksModal/EmailInvalidLinksModal';
import { EmailUnresolvedPlaceholdersModal } from 'components/emails/EmailUnresolvedPlaceholdersModal/EmailUnresolvedPlaceholdersModal';
import { PropertiesExposeWarning } from 'components/emails/PropertiesExposeWarning/PropertiesExposeWarning';
import { AppointmentForEmail } from 'components/emails/SendEmailPopover/AppointmentForEmail';
import { CcRecipientPicker } from 'components/emails/SendEmailPopover/CcRecipientPicker';
import { RecipientPicker } from 'components/emails/SendEmailPopover/RecipientPicker';
import { SendEmailFooter } from 'components/emails/SendEmailPopover/SendEmailFooter';
import { SendEmailPopoverHeader } from 'components/emails/SendEmailPopover/SendEmailPopoverHeader';
import { SharedLead } from 'components/emails/SendEmailPopover/SharedLead';
import { hasInvalidEmailLinks } from 'components/emails/SendEmailPopover/urlValidator';
import { useEmailEditor } from 'components/emails/SendEmailPopover/useEmailEditor';
import { SignatureMenu } from 'components/emails/SignatureMenu';
import { TemplateSelector } from 'components/emails/TemplatesSelector/TemplateSelector';
import { NylasIntegratedEmails } from 'components/emails/utils';
import { RichTextEditor } from 'components/forms/RichTextEditor/RichTextEditor';
import { RichTextContainer } from 'components/forms/RichTextEditor/styles';
import { FileUploadButton } from 'components/general/FileUploadButton/FileUploadButton';
import PaperClip from 'components/icons/paper_clip.svg?react';
import { PropertySearchField } from 'components/property/PropertySearch/PropertySearchField';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import uniqBy from 'lodash.uniqby';
import { useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { theme } from 'theme';
import {
  countUnresolvedPlaceholders,
  getAutoCcRecipients,
  getId,
  getPreferredLanguage,
  highlightUnresolvedPlaceholders,
} from 'util/email';
import { isSet } from 'util/filters';
import { useTranslation } from 'util/i18next';
import { recipientMapper, sendPopoverEmailMapper } from 'util/mappers/sendEmailMapper';
import { EmailFormData, Recipient } from 'util/schemas/sendEmailSchema';
import { useErrorSnackBar } from 'util/useErrorSnackBar';

interface SendEmailFormProps {
  possibleFromEmails: NylasIntegratedEmails[];
  onClose: () => void;
}

export function SendEmailForm({ possibleFromEmails, onClose }: SendEmailFormProps) {
  const { t } = useTranslation(['communication']);
  const { openSnackBar } = useSnackBars();
  const { openErrorSnackBar } = useErrorSnackBar();
  const { sendEmail, isLoading: isSending } = useSendEmail();
  const { resolveEmailSignature } = useResolveEmailSignature();
  const { resolveEmailTemplate } = useResolveEmailTemplate();
  const { insertTemplateBody, insertSignature } = useEmailEditor();
  const { openSendBulkEmail } = useEmailSending();
  const { activeShop, activeShopSettings } = useActiveShop();

  const [hasInvalidLinksModalOpen, setHasInvalidLinksModalOpen] = useState(false);
  const [hasInvalidedUrls, setHasInvalidedUrls] = useState(false);
  const [warningClosed, setWarningClosed] = useState(false);
  const {
    control,
    watch,
    setValue,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext<EmailFormData>();

  const [
    recipientsTo,
    cc,
    subject,
    properties,
    attachments,
    sharedLeadId,
    appointmentId,
    toContactsWithoutEmail,
    toContactsFlagged,
    responseToType,
  ] = watch([
    'to',
    'cc',
    'subject',
    'properties',
    'attachments',
    'sharedLeadId',
    'appointmentId',
    'toContactsWithoutEmail',
    'toContactsFlagged',
    'responseToType',
  ]);

  const isReply = !!responseToType && responseToType !== 'forward';

  const isForward = !!responseToType && responseToType === 'forward';

  const [expandedCcSection, setExpandedCcSection] = useState(false);
  const [expandedBccSection, setExpandedBccSection] = useState(false);

  const showPropertiesExposeWarning = !sharedLeadId && properties.length > 0 && !warningClosed;

  const [submittedDataWithUnresolvedPlaceholders, setSubmittedDataWithUnresolvedPlaceholders] =
    useState<EmailFormData>();

  const insertResolvedSignature = ({ shopId, emailSignatureId }: { shopId: string; emailSignatureId?: string }) => {
    resolveEmailSignature(
      {
        shopId,
        emailSignatureId,
      },
      {
        onSuccess: ({ data: { data } }) => {
          if (data) {
            insertSignature(data);
          }
        },
        onError: (error) => {
          openErrorSnackBar(t('communication:newEmail.signatures.resolve.error'), error);
        },
      },
    );
  };

  const insertResolvedTemplate = (templateId: string) => {
    resolveEmailTemplate(
      {
        body: {
          recipients: recipientsTo.map(recipientMapper),
          emailTemplateId: templateId,
          shopId: activeShop.id,
          language: getPreferredLanguage(recipientsTo) || activeShopSettings?.defaultPreferredLanguage || 'EN',
          ...(properties?.length && { propertyIds: properties.map((p) => p.utag) }),
          appointmentId,
          sharedLeadId,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
      {
        onSuccess: (data) => {
          insertTemplateBody(highlightUnresolvedPlaceholders(data!.body!, data!.unresolvedPlaceholders!));
          setValue('subject', data!.subject!, { shouldValidate: true });
        },
        onError: (error) => openErrorSnackBar(t('communication:newEmail.templates.resolve.error'), error),
      },
    );
  };

  const handleTemplateSelected = (template: EmailTemplate) => {
    setValue('templateId', template.id);
    insertResolvedTemplate(template.id);
  };
  const addAutoCcForNewRecipients = (recipients: Recipient[]) => {
    const currentRecipientIds = getValues('to').map(getId);
    const newRecipients = recipients
      .filter((r) => !currentRecipientIds.includes(getId(r)))
      .filter((r) => r.type === 'CONTACT' || r.type === 'LEAD');

    if (newRecipients.length) {
      const ccRecipients = uniqBy([...getValues('cc'), ...getAutoCcRecipients(newRecipients, recipients)], getId);
      setValue('cc', ccRecipients);
    }
  };

  const onSend = handleSubmit((data) => {
    const hasUnresolvedPlaceholders = !!countUnresolvedPlaceholders(data.body);
    if (hasUnresolvedPlaceholders) {
      setSubmittedDataWithUnresolvedPlaceholders(data);
    } else {
      doSend(data);
    }
  });

  const onSendClick = () => {
    if (hasInvalidEmailLinks(watch('body'))) {
      setHasInvalidedUrls(true);
      setHasInvalidLinksModalOpen(true);
    } else {
      onSend();
    }
  };

  const blockSending = useRef(false);
  const doSend = (data: EmailFormData) => {
    // There are constant issues with emails being sent multiple time.
    if (blockSending.current) {
      datadogRum.addAction('Duplicate email sending blocked');
      return;
    }

    blockSending.current = true;
    const payload = sendPopoverEmailMapper(data, hasInvalidedUrls, attachments, activeShop.id);
    sendEmail(payload, {
      onSuccess: () => {
        openSnackBar(t('communication:newEmail.submit.success'), 'success');
        onClose();
      },
      onError: (error) => {
        blockSending.current = false;
        openErrorSnackBar(t('communication:newEmail.submit.error'), error);
      },
    });
  };

  const noEmailIntegration = !possibleFromEmails.length;

  const switchToExposeSending = () => {
    openSendBulkEmail({
      propertyUtags: properties.map((property) => property.utag).filter(isSet),
      template: 'EXPOSE',
      to: recipientsTo
        .map((recipient) => {
          switch (recipient.type) {
            case 'LEAD':
              return { leadId: recipient.id };
            case 'CONTACT':
            case 'RELATED_CONTACT':
              return { contactId: recipient.id };
            case 'AGENT':
            case 'EMAIL':
              return;
          }
        })
        .filter(isSet),
    });
    onClose();
  };

  return (
    <>
      <Controller
        name="from"
        control={control}
        render={({ field }) => (
          <SendEmailPopoverHeader
            from={field.value}
            onChangeFrom={field.onChange}
            noEmailIntegration={noEmailIntegration}
            possibleFromEmails={possibleFromEmails}
          />
        )}
      />
      <Stack sx={{ padding: 3, flex: 1, opacity: noEmailIntegration ? 0.2 : undefined }} gap={3}>
        {sharedLeadId && <SharedLead leadId={sharedLeadId} />}
        {appointmentId && <AppointmentForEmail appointmentId={appointmentId} />}
        <ContactsRemovedAlert toContactsFlagged={toContactsFlagged} toContactsWithoutEmail={toContactsWithoutEmail} />

        <Controller
          name="to"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <RecipientPicker
              restrictToAgents={!!sharedLeadId}
              value={field.value}
              disabled={noEmailIntegration || isReply}
              onChange={(recipients) => {
                addAutoCcForNewRecipients(recipients);
                field.onChange(recipients);
              }}
              errorMessage={error?.message}
              label={
                sharedLeadId
                  ? t('communication:emailPopover.sharedLeadRecipients')
                  : t('communication:emailPopover.recipients')
              }
            >
              <CcBccButtons
                disabled={noEmailIntegration}
                hasCc={!!watch('cc').length}
                hasBcc={!!watch('bcc').length}
                expandedCcSection={expandedCcSection}
                expandedBccSection={expandedBccSection}
                setExpandedCcSection={setExpandedCcSection}
                setExpandedBccSection={setExpandedBccSection}
              />
            </RecipientPicker>
          )}
        />

        {(expandedCcSection || cc.length > 0) && (
          <Controller
            name="cc"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <CcRecipientPicker
                value={field.value}
                disabled={noEmailIntegration}
                onChange={field.onChange}
                onBlur={() => {
                  if (!watch('cc')?.length) {
                    setExpandedCcSection(false);
                  }
                }}
                errorMessage={error?.message}
                label={t('communication:emailPopover.cc')}
              />
            )}
          />
        )}
        {expandedBccSection && (
          <Controller
            name="bcc"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <CcRecipientPicker
                value={field.value}
                disabled={noEmailIntegration}
                onChange={field.onChange}
                onBlur={() => {
                  if (!watch('bcc')?.length) {
                    setExpandedBccSection(false);
                  }
                }}
                errorMessage={error?.message}
                label={t('communication:emailPopover.bcc')}
              />
            )}
          />
        )}
        <Box>
          <PropertySearchField hideArrow disabled={noEmailIntegration} isEmail={true} />
          {showPropertiesExposeWarning && !isReply && !isForward && (
            <PropertiesExposeWarning
              description={t('communication:email.sendExposeWarningDescription')}
              onAction={() => switchToExposeSending()}
              onClose={() => setWarningClosed(true)}
            />
          )}
        </Box>
        <Box sx={{ display: 'flex', position: 'relative' }}>
          <TextField
            required
            disabled={noEmailIntegration || isReply || isForward}
            value={subject}
            label={t('communication:emailPopover.subject')}
            error={!!errors.subject}
            helperText={errors.subject?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <TemplateSelector onTemplateSelected={handleTemplateSelected} />
                </InputAdornment>
              ),
            }}
            {...register('subject')}
          />
        </Box>
        <Controller
          name="body"
          control={control}
          render={({ field }) => (
            <RichTextContainer data-testid="richTextContainer">
              <RichTextEditor
                disabled={noEmailIntegration}
                showToolbarOnTop
                theme="snow"
                value={field.value}
                onChange={field.onChange}
                errorMessage={errors.body?.message}
                extraToolBarItems={
                  <>
                    <Controller
                      name="signatureId"
                      control={control}
                      render={() => (
                        <SignatureMenu onSignatureSelected={insertResolvedSignature} disabled={noEmailIntegration} />
                      )}
                    />
                    <Controller
                      name="attachments"
                      control={control}
                      render={({ field }) => (
                        <FileUploadButton
                          allowMultipleSelection
                          icon={<PaperClip />}
                          disabled={noEmailIntegration}
                          byteSumOfAllUploads={field.value.reduce((acc, file) => acc + file.size, 0)}
                          onUpload={(files) => field.onChange([...field.value, ...files])}
                        />
                      )}
                    />
                  </>
                }
              />
              <Controller
                name="attachments"
                control={control}
                render={({ field }) => (
                  <Box>
                    {field.value.map((file, index) => (
                      <Attachment
                        key={`attachment-${index}`}
                        name={file.name}
                        size={file.size}
                        onRemove={() => field.onChange(field.value.filter((f) => f !== file))}
                      />
                    ))}
                  </Box>
                )}
              />
            </RichTextContainer>
          )}
        />

        {submittedDataWithUnresolvedPlaceholders && (
          <EmailUnresolvedPlaceholdersModal
            onCancel={() => setSubmittedDataWithUnresolvedPlaceholders(undefined)}
            onConfirm={() => {
              setSubmittedDataWithUnresolvedPlaceholders(undefined);
              doSend(submittedDataWithUnresolvedPlaceholders);
            }}
            sendingDisabled={isSending}
          />
        )}
        {hasInvalidLinksModalOpen && (
          <EmailInvalidLinksModal
            onCancel={() => {
              setHasInvalidLinksModalOpen(false);
              setHasInvalidedUrls(false);
            }}
            onConfirm={() => {
              setHasInvalidLinksModalOpen(false);
              onSend();
            }}
            sendingDisabled={isSending}
          />
        )}
      </Stack>
      <SendEmailFooter
        primaryAction={{
          primaryButtonLabel: t('communication:emailForm.send', {
            number: '',
          }),
          callback: onSendClick,
          disabled: noEmailIntegration || isSending,
        }}
        isLoading={isSending}
      />
    </>
  );
}

const CcBccButtons = ({
  disabled,
  hasCc,
  hasBcc,
  expandedCcSection,
  expandedBccSection,
  setExpandedCcSection,
  setExpandedBccSection,
}: {
  disabled?: boolean;
  hasCc: boolean;
  hasBcc: boolean;
  expandedCcSection: boolean;
  expandedBccSection: boolean;
  setExpandedCcSection: (e: boolean) => void;
  setExpandedBccSection: (e: boolean) => void;
}) => {
  const { t } = useTranslation(['communication']);
  return (
    <Box sx={{ marginY: -1, alignItems: 'center', display: 'flex', justifyContent: 'flex-end' }}>
      <Button
        sx={{
          width: '24px',
          height: '22px',
          minWidth: 'unset',
          color: theme.palette.shade.grey2,
          backgroundColor: expandedCcSection ? theme.palette.shade.grey3 : 'transparent',
          px: 2,
          '&:hover': {
            backgroundColor: theme.palette.shade.grey3,
          },
        }}
        onClick={() => setExpandedCcSection(!expandedCcSection)}
        disabled={hasCc || disabled}
      >
        {t('communication:emailPopover.cc')}
      </Button>
      <Button
        sx={{
          width: '24px',
          height: '22px',
          minWidth: 'unset',
          color: theme.palette.shade.grey2,
          backgroundColor: expandedBccSection ? theme.palette.shade.grey3 : 'transparent',
          marginLeft: 1,
          px: 2,
          '&:hover': {
            backgroundColor: theme.palette.shade.grey3,
          },
        }}
        onClick={() => setExpandedBccSection(!expandedBccSection)}
        disabled={hasBcc || disabled}
      >
        {t('communication:emailPopover.bcc')}
      </Button>
    </Box>
  );
};
