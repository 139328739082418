import { UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SessionStatusEnum } from 'api/graphql/generated/graphql';
import { HttpService } from 'api/rest/HttpService';
import { AcceptReferralResponse } from 'api/rest/models/AcceptReferralReponse';
import { ConsentTextResponse } from 'api/rest/models/ConsentTextResponse';
import { DuplicateContactInfo } from 'api/rest/models/ContactDuplicates';
import {
  EmailMessageProperties,
  GetNylasEmailThreadsResponse,
  PreviewEmailResponse,
} from 'api/rest/models/EmailDataTypes';
import { KycCheckResponse } from 'api/rest/models/KycCheckResponse';
import { LeadResponse } from 'api/rest/models/LeadResponse';
import { PropertyTypeMapping } from 'api/rest/models/PropertyTypeMapping';
import { TwilioSession } from 'api/rest/models/TwilioSession';
import { Go3ActivityFeatures } from 'api/rest/models/go3/Go3ActivityFeatures';
import { Go3ContactResponse } from 'api/rest/models/go3/Go3ContactResponse';
import { Go3TeamsResponse } from 'api/rest/models/go3/Go3TeamsResponse';
import {
  AcceptReferralRequest,
  AddBuyerRentalLeadRequest,
  AddSellerRenterLeadRequest,
  BulkUpdateLeadStatusRequest,
  BuyMobileNumberRequest,
  CompositeCdfCommandRequest,
  ConsentTextRequest,
  CreateAppointmentRequest,
  CreateBuyerRentalLeadRequest,
  CreateManualConsentRequest,
  CreateTaskRequest,
  DeLinkEmailToContactRequest,
  DeLinkEmailToLeadRequest,
  DeclineTwilioCallRequest,
  DeleteEmailTemplateRequest,
  DestroyTwilioSessionRequest,
  DownloadDocumentReferenceRequest,
  EditBuyerRentalLeadRequest,
  EditSellerRenterLeadRequest,
  GetCallingAvailabilityRequest,
  GetContactDuplicatesRequest,
  GetGo3ContactRequest,
  GetGo3TeamsRequest,
  GetKycCheckCommandRequest,
  GetNylasEmailMessagesForThreadRequest,
  GetNylasEmailThreadsRequest,
  GetNylasOneTimeTokenRequest,
  LinkEmailToContactRequest,
  LinkEmailToLeadRequest,
  LinkLeadsRequest,
  LinkPropertyToSellerLeadRequest,
  MergeContactRequest,
  MergeDuplicateContactsRequest,
  ModifyDocumentRequest,
  OverwriteLinkGo3ContactRequest,
  PingRequest,
  PreviewReferralEmailRequest,
  ReassignAgentsOnContactRequest,
  RejectReferralRequest,
  RequestConsentRequest,
  RequestKycCheckCommandRequest,
  RerunFailedLeadImportsRequest,
  RerunKycCheckCommandRequest,
  ResolveEmailSignatureRequest,
  ResolvePreviewEmailTemplateRequest,
  RetrieveGo3ActivityRequest,
  RevokeConsentsRequest,
  SearchForContactInReceiverShopRequest,
  ShareExposeRequest,
  UnlinkLeadsRequest,
  UnlinkPropertyToSellerLeadRequest,
  UpdateAppointmentRequest,
  UpdateCallActivityRequest,
  UpdateContactRequest,
  UpdateFailedLeadImportsRequest,
  UpdateLeadAssignmentsRequest,
  UpdateLeadDetailsRequest,
  UpdateLeadExtendingInteractionsRequest,
  UpdateNylasEmailThreadRequest,
  UpdateShopEnergyAdvisoryRequest,
  UpdateTaskRequest,
  UpdateTwilioSessionRequest,
  UpdateUserRoleWithinShopRequest,
  UploadDocumentsRequest,
  UpsertShopProfileEmailFooterRequest,
  UpsertTagsCommandRequest,
  VerifyMobileNumberRequest,
  updateShopManagedConsentSettingsRequest,
} from 'api/rest/models/rest/Command';
import { env } from 'env';
import { updatePageToken } from 'page-components/mails/ThreadPage/pageToken';
import { useDestination } from 'util/navigation/useDestination';
import { useNavigation } from 'util/navigation/useNavigation';

export const httpService = new HttpService();

if (['local', 'local-dev', 'feature', 'dev', 'stage'].includes(env.VITE_LEADHUB_ENV)) {
  // expose API for E2E tests
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).leadEngineService = httpService;
}

export const useCreateTwilioSession = ({ onSuccess }: { onSuccess: (response: TwilioSession) => void }) => {
  const { mutate: createTwilioSession, ...rest } = useMutation({
    mutationFn: () =>
      httpService
        .executeLeadEngineCommand({
          requestBody: {},
          className: 'CreateTwilioSessionCommand',
        })
        .then((resp) => resp.data.data as TwilioSession),
    onSuccess,
  });
  return { createTwilioSession, ...rest };
};

export const useDestroyTwilioSession = () => {
  const { mutate: destroyTwilioSession, ...rest } = useMutation((requestBody: DestroyTwilioSessionRequest) =>
    httpService.executeLeadEngineCommand({
      requestBody,
      className: 'DestroyTwilioSessionCommand',
    }),
  );
  return { destroyTwilioSession, ...rest };
};

export const usePing = () => {
  const { mutate: ping, ...rest } = useMutation((requestBody: PingRequest) =>
    httpService.executeLeadEngineCommand({ requestBody, className: 'PingCommand' }).then((resp) => resp.data.data),
  );
  return { ping, ...rest };
};

export const useUpdateContact = () => {
  const queryClient = useQueryClient();
  const { mutate: updateContact, ...rest } = useMutation(
    (requestBody: UpdateContactRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpdateContactCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { updateContact, ...rest };
};

export const useTriggerMigration = () => {
  const { mutate: triggerMigration, ...rest } = useMutation((ShopId: string) => httpService.executeMigration(ShopId));
  return { triggerMigration, ...rest };
};

export const useUpdateLeadDetails = () => {
  const queryClient = useQueryClient();
  const { mutate: updateLeadDetails, ...rest } = useMutation(
    (requestBody: UpdateLeadDetailsRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpdateLeadDetailsCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['matching'] });
      },
    },
  );
  return { updateLeadDetails, ...rest };
};

export const useUpdateTwilioSession = () => {
  const { mutate: updateTwilioSession, ...rest } = useMutation((requestBody: UpdateTwilioSessionRequest) =>
    httpService
      .executeLeadEngineCommand({ requestBody, className: 'UpdateTwilioSessionCommand' })
      .then((resp) => resp.data.data as { sessionStatus: SessionStatusEnum; preflightCheck: boolean }),
  );
  return { updateTwilioSession, ...rest };
};
export const usePreviewAssignmentEmailTemplate = () => {
  const { mutate: resolvePreviewEmailTemplate, ...rest } = useMutation(
    (requestBody: ResolvePreviewEmailTemplateRequest) =>
      httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'PreviewAssignmentEmailCommand',
        })
        .then((resp) => resp.data.data as PreviewEmailResponse),
  );
  return { resolvePreviewEmailTemplate, ...rest };
};

export const usePreviewReferralEmailTemplate = () => {
  const { mutate: resolvePreviewReferralEmailTemplate, ...rest } = useMutation(
    (requestBody: PreviewReferralEmailRequest) =>
      httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'PreviewReferralEmailCommand',
        })
        .then((resp) => resp.data.data as PreviewEmailResponse[]),
  );
  return { resolvePreviewReferralEmailTemplate, ...rest };
};

export const useSearchForContactInReceiverShop = () => {
  const { mutateAsync: searchForContactInReceiverShopAsync, ...rest } = useMutation(
    (requestBody: SearchForContactInReceiverShopRequest) =>
      httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'SearchForContactInReceiverShopCommand',
        })
        .then((resp) => resp.data.data as DuplicateContactInfo[]),
  );
  return { searchForContactInReceiverShopAsync, ...rest };
};

export const useGetPropertyTypesAndSubtypesCommand = () => {
  const { mutate: getPropertyTypesAndSubtypesCommand, ...rest } = useMutation(() =>
    httpService
      .executeLeadEngineCommand({
        requestBody: {},
        className: 'GetPropertyTypesAndSubtypesCommand',
      })
      .then((resp) => resp.data.data as PropertyTypeMapping),
  );
  return { getPropertyTypesAndSubtypesCommand, ...rest };
};
export const useResolveEmailSignature = () => {
  const {
    mutate: resolveEmailSignature,
    mutateAsync: resolveEmailSignatureAsync,
    ...rest
  } = useMutation((requestBody: ResolveEmailSignatureRequest) =>
    httpService.executeLeadEngineCommand({
      requestBody,
      className: 'ResolveEmailSignatureCommand',
    }),
  );
  return { resolveEmailSignature, resolveEmailSignatureAsync, ...rest };
};

export function useResolvedSignature(params: { shopId: string; emailSignatureId?: string }) {
  const { resolveEmailSignatureAsync } = useResolveEmailSignature();
  const { data, ...rest } = useQuery({
    queryKey: ['email', 'useResolvedSignature', params],
    queryFn: async () => (await resolveEmailSignatureAsync(params)).data.data || '',
  });
  return { signature: data, ...rest };
}

export const useDeleteEmailTemplate = () => {
  const queryClient = useQueryClient();
  const { mutate: deleteEmailTemplate, ...rest } = useMutation(
    (requestBody: DeleteEmailTemplateRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'DeleteEmailTemplateCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['email'] });
      },
    },
  );
  return { deleteEmailTemplate, ...rest };
};

export const useGetTeamMembersFromGo3 = (teamId?: string) => {
  const { data, ...rest } = useQuery({
    enabled: !!teamId,
    queryKey: ['go3', 'useGetTeamMembersFromGo3', teamId],
    queryFn: () => {
      if (!teamId) {
        return;
      }

      return httpService
        .executeLeadEngineCommand({
          requestBody: { teamId },
          className: 'GetTeamMembersCommand',
        })
        .then((resp) => resp.data.data);
    },
  });
  return { membersData: data, ...rest };
};

export const useGetNylasOneTimeToken = () => {
  const { mutate: getNylasOneTimeToken, ...rest } = useMutation((requestBody: GetNylasOneTimeTokenRequest) =>
    httpService.executeLeadEngineCommand({
      requestBody,
      className: 'GetNylasOneTimeTokenCommand',
    }),
  );
  return { getNylasOneTimeToken, ...rest };
};

export const getGo3DuplicateContacts = (requestBody: GetContactDuplicatesRequest) =>
  httpService
    .executeLeadEngineCommand({
      requestBody,
      className: 'GetContactDuplicatesCommand',
    })
    .then((resp) => resp.data.data as Go3ContactResponse[]);

export const getGo3Contact = (requestBody: GetGo3ContactRequest) =>
  httpService
    .executeLeadEngineCommand({
      requestBody,
      className: 'GetGo3ContactCommand',
    })
    .then((resp) => resp.data.data as Go3ContactResponse);

export const useOverwriteLinkGo3Contact = () => {
  const queryClient = useQueryClient();
  const { mutate: overwritelinkGo3Contact, ...rest } = useMutation(
    (requestBody: OverwriteLinkGo3ContactRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'OverwriteGo3ContactCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { overwritelinkGo3Contact, ...rest };
};

export const useCreateBuyerRentalLead = () => {
  const queryClient = useQueryClient();
  const { mutate: createBuyerRentalLead, ...rest } = useMutation(
    (requestBody: CreateBuyerRentalLeadRequest) =>
      httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'CreateBuyerRentalLeadCommand',
        })
        .then((resp) => resp.data.data as LeadResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { createBuyerRentalLead, ...rest };
};
export const useMergeContacts = () => {
  const queryClient = useQueryClient();
  const { replace } = useNavigation();
  const { toContactDetails } = useDestination();

  const { mutate: mergeContacts, ...rest } = useMutation(
    (requestBody: MergeContactRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'MergeContactCommand',
      });
    },
    {
      onSuccess: (_, { targetContactId }) => {
        // The navigation needs to happen before the invalidation to prevent issues with the
        // redirect on the contact details page
        replace(toContactDetails({ contactId: targetContactId }));
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { mergeContacts, ...rest };
};

export const useAddNewSellerRenterLead = () => {
  const queryClient = useQueryClient();
  const { mutate: addNewSellerRenterLead, ...rest } = useMutation(
    (requestBody: AddSellerRenterLeadRequest) =>
      httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'AddSellerRenterLeadCommand',
        })
        .then((resp) => resp.data.data as LeadResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { addNewSellerRenterLead, ...rest };
};

export const useAddNewBuyerRentalLead = () => {
  const queryClient = useQueryClient();
  const { mutate: addNewBuyerRentalLead, ...rest } = useMutation(
    (requestBody: AddBuyerRentalLeadRequest) =>
      httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'AddBuyerRentalLeadCommand',
        })
        .then((resp) => resp.data.data as LeadResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { addNewBuyerRentalLead, ...rest };
};

export const useEditSellerRenterLead = () => {
  const queryClient = useQueryClient();
  const { mutate: editSellerRenterLead, ...rest } = useMutation(
    (requestBody: EditSellerRenterLeadRequest) =>
      httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'EditSellerRenterLeadCommand',
        })
        .then((resp) => resp.data.data as LeadResponse),
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ['lead'] }),
    },
  );
  return { editSellerRenterLead, ...rest };
};

export const useEditBuyerRentalLead = () => {
  const queryClient = useQueryClient();
  const { mutate: editBuyerRentalLead, ...rest } = useMutation(
    (requestBody: EditBuyerRentalLeadRequest) =>
      httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'EditBuyerRentalLeadCommand',
        })
        .then((resp) => resp.data.data as LeadResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['matching'] });
      },
    },
  );
  return { editBuyerRentalLead, ...rest };
};

export const useRetrieveGo3Activities = (requestBody: RetrieveGo3ActivityRequest, disableQuery?: boolean) =>
  useQuery({
    queryKey: ['retrieveGo3Activities', requestBody],
    enabled: !!requestBody.go3ContactUtag && !disableQuery,
    queryFn: () => {
      return httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'RetrieveGo3ActivityCommand',
        })
        .then((resp) => resp.data.data as Go3ActivityFeatures[]);
    },
  });

export const useUpdateUserRoleWithinShop = () => {
  const queryClient = useQueryClient();
  const { mutate: updateUserRoleWithinShop, ...rest } = useMutation(
    (requestBody: UpdateUserRoleWithinShopRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpdateUserRoleWithinShopCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['agent'] });
      },
    },
  );
  return { updateUserRoleWithinShop, ...rest };
};

export const useRevokeConsents = () => {
  const queryClient = useQueryClient();
  const { mutate: revokeConsents, ...rest } = useMutation(
    (requestBody: RevokeConsentsRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'RevokeConsentsCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { revokeConsents, ...rest };
};

export const useGetConsentTexts = (requestBody: ConsentTextRequest) =>
  useQuery({
    queryKey: ['consentTexts', 'useGetConsentTexts', requestBody],
    enabled: !!requestBody.consentTypes.length,
    queryFn: () => {
      return httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'GetConsentTextCommand',
        })
        .then((resp) => resp.data.data as ConsentTextResponse[]);
    },
  });

export function buildNylasSearchQuery(searchText: string | undefined) {
  return searchText ? `{from:${searchText} to:${searchText} subject:${searchText} "${searchText}"}` : undefined;
}

export const useGetNylasEmailThreads = (
  parameters: GetNylasEmailThreadsRequest & { page: number; limit: number },
  options?: UseQueryOptions<GetNylasEmailThreadsResponse>,
) => {
  return useQuery({
    queryKey: ['email', 'useGetNylasEmailThreads', parameters],
    queryFn: async () => {
      const { page, ...requestBody } = parameters;
      const resp = await httpService.executeLeadEngineCommand({
        requestBody,
        className: 'GetNylasEmailThreadsCommand',
      });
      const threadsResponse = resp.data.data as GetNylasEmailThreadsResponse;

      if (threadsResponse.nextCursor) {
        updatePageToken({
          emailIntegration: parameters.emailAddress,
          page: page + 1,
          pageSize: parameters.limit,
          token: threadsResponse.nextCursor,
        });
      }
      return threadsResponse;
    },
    ...options,
    enabled: !!parameters.emailAddress && options?.enabled,
  });
};

export const useGetNylasEmailMessagesForThread = () => {
  const { mutate: getNylasEmailMessagesForThread, ...rest } = useMutation(
    (requestBody: GetNylasEmailMessagesForThreadRequest) =>
      httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'GetNylasEmailMessagesForThreadCommand',
        })
        .then((resp) => resp.data.data as EmailMessageProperties[]),
  );
  return { getNylasEmailMessagesForThread, ...rest };
};

export const useGetNylasUnreadThreadCount = (enabled: boolean, integratedEmail?: string) => {
  return useQuery({
    enabled: !!integratedEmail && enabled,
    queryKey: ['email', integratedEmail, 'threadCount'],
    queryFn: () =>
      httpService
        .executeLeadEngineCommand({
          requestBody: { emailAddress: integratedEmail ?? '' },
          className: 'GetNylasUnreadThreadCountCommand',
        })
        .then((resp) => resp.data.data),
  });
};

export const useGetGo3Teams = () => {
  const { mutate: getGo3Teams, ...rest } = useMutation((requestBody: GetGo3TeamsRequest) =>
    httpService
      .executeLeadEngineCommand({
        requestBody,
        className: 'GetGo3TeamsCommand',
      })
      .then((resp) => resp.data.data as Go3TeamsResponse),
  );
  return { getGo3Teams, ...rest };
};

export const useUpdateNylasEmailThread = () => {
  const { mutate: updateNylasEmailThread, ...rest } = useMutation((requestBody: UpdateNylasEmailThreadRequest) =>
    httpService.executeLeadEngineCommand({
      requestBody,
      className: 'UpdateNylasEmailThreadCommand',
    }),
  );
  return { updateNylasEmailThread, ...rest };
};

export const useLinkEmailToLead = () => {
  const queryClient = useQueryClient();
  const { mutate: linkEmailToLead, ...rest } = useMutation(
    (requestBody: LinkEmailToLeadRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'LinkEmailToLeadCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['email'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { linkEmailToLead, ...rest };
};

export const useDeLinkEmailToLead = () => {
  const queryClient = useQueryClient();
  const { mutate: deLinkEmailToLead, ...rest } = useMutation(
    (requestBody: DeLinkEmailToLeadRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'DeLinkEmailToLeadCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['email'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { deLinkEmailToLead, ...rest };
};

export const useLinkEmailToContact = () => {
  const queryClient = useQueryClient();
  const { mutate: linkEmailToContact, ...rest } = useMutation(
    (requestBody: LinkEmailToContactRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'LinkEmailToContactCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['email'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { linkEmailToContact, ...rest };
};

export const useDeLinkEmailToContact = () => {
  const queryClient = useQueryClient();
  const { mutate: deLinkEmailToContact, ...rest } = useMutation(
    (requestBody: DeLinkEmailToContactRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'DeLinkEmailToContactCommand',
      }),
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ['email'] }),
    },
  );
  return { deLinkEmailToContact, ...rest };
};

export const useRerunFailedLeadImports = () => {
  const { mutate: rerunFailedLeadImports, ...rest } = useMutation((requestBody: RerunFailedLeadImportsRequest) => {
    return httpService.executeLeadEngineCommand({
      requestBody,
      className: 'RerunFailedLeadImportsCommand',
    });
  });

  const { mutate: deleteFailedLeadImports, ...deleteRest } = useMutation(
    (requestBody: UpdateFailedLeadImportsRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpdateFailedLeadImportsCommand',
      });
    },
  );

  return {
    rerunFailedLeadImports,
    deleteFailedLeadImports,
    ...rest,
    deleteRest,
  };
};

export const useBuyNumber = () => {
  const queryClient = useQueryClient();
  const { mutate: buyMobileNumber, ...rest } = useMutation(
    (requestBody: BuyMobileNumberRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'BuyTwilioNumberCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['verifyNumber'] });
      },
    },
  );
  return { buyMobileNumber, ...rest };
};

export const useAddCallerIdNumber = () => {
  const queryClient = useQueryClient();
  const { mutate: addCallerIdNumber, ...rest } = useMutation(
    (requestBody: VerifyMobileNumberRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'AddTwilioOutgoingCallerIdCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['verifyNumber'] });
      },
    },
  );
  return {
    addCallerIdNumber,
    validationCode: rest?.data?.data?.data?.validationCode,
    ...rest,
  };
};

export const useCheckIsCallingAvailable = (requestBody: GetCallingAvailabilityRequest) =>
  useQuery({
    queryKey: ['verifyNumber', 'useCheckIsCallingAvailable'],
    queryFn: async () => {
      const resp = await httpService.executeLeadEngineCommand({
        requestBody,
        className: 'CheckCallingFunctionalityAvailableCommand',
      });
      return resp.data.data;
    },
    refetchInterval: 5000,
  });

export const useUpdateShopManagedConsentSettings = () => {
  const queryClient = useQueryClient();
  const { mutate: updateShopManagedConsentSettings, ...rest } = useMutation(
    (requestBody: updateShopManagedConsentSettingsRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpsertShopManagedConsentCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['shop'] });
      },
    },
  );
  return { updateShopManagedConsentSettings, ...rest };
};

export const useReassignAgentsCommand = () => {
  const queryClient = useQueryClient();

  const { mutate: reassignAgents, ...rest } = useMutation(
    (requestBody: ReassignAgentsOnContactRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'ReassignAgentsOnContactCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { reassignAgents, ...rest };
};

export const useUpsertShopProfileEmailFooter = () => {
  const queryClient = useQueryClient();
  const { mutate: upsertShopProfileEmailFooter, ...rest } = useMutation(
    (requestBody: UpsertShopProfileEmailFooterRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpsertShopProfileCustomerMailsCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['shopProfile'] });
      },
    },
  );
  return { upsertShopProfileEmailFooter, ...rest };
};

export const useUpdateCallActivity = () => {
  const queryClient = useQueryClient();
  const { mutate: updateCallActivity, ...rest } = useMutation(
    (requestBody: UpdateCallActivityRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpdateCallActivityCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { updateCallActivity, ...rest };
};

export const useCreateTask = () => {
  const queryClient = useQueryClient();
  const { mutate: createTask, ...rest } = useMutation(
    (requestBody: CreateTaskRequest) => {
      return httpService.executeLeadEngineCommand({ requestBody, className: 'CreateTaskCommand' });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['user'] });
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['agent'] });
        queryClient.invalidateQueries({ queryKey: ['activity'] });
      },
    },
  );
  return { createTask, ...rest };
};

export const useCreateAppointment = () => {
  const queryClient = useQueryClient();
  const { mutate: createAppointment, ...rest } = useMutation(
    (requestBody: CreateAppointmentRequest) => {
      return httpService.executeLeadEngineCommand({ requestBody, className: 'CreateAppointmentCommand' });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['user'] });
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['agent'] });
        queryClient.invalidateQueries({ queryKey: ['activity'] });
      },
    },
  );
  return { createAppointment, ...rest };
};

export const useShareExpose = () => {
  const queryClient = useQueryClient();
  const { mutate: shareExpose, ...rest } = useMutation(
    (requestBody: ShareExposeRequest) => {
      return httpService.executeLeadEngineCommand({ requestBody, className: 'CreateDocumentSharedActivityCommand' });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { shareExpose, ...rest };
};

export const useAcceptReferral = () => {
  const queryClient = useQueryClient();
  const { mutate: acceptReferral, ...rest } = useMutation(
    (requestBody: AcceptReferralRequest) =>
      httpService
        .executeLeadEngineCommand({
          requestBody,
          className: 'AcceptReferralCommand',
        })
        .then((resp) => resp.data.data as AcceptReferralResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['referral'] });
      },
    },
  );
  return { acceptReferral, ...rest };
};

export const useRejectReferral = () => {
  const queryClient = useQueryClient();
  const { mutate: rejectReferral, ...rest } = useMutation(
    (requestBody: RejectReferralRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'RejectReferralCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['referral'] });
      },
    },
  );
  return { rejectReferral, ...rest };
};

export const useUpdateTask = () => {
  const queryClient = useQueryClient();
  const {
    mutate: updateTask,
    mutateAsync: updateTaskAsync,
    ...rest
  } = useMutation(
    (requestBody: UpdateTaskRequest) => {
      return httpService.executeLeadEngineCommand({ requestBody, className: 'UpdateTaskCommand' });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['user'] });
        queryClient.invalidateQueries({ queryKey: ['agent'] });
        queryClient.invalidateQueries({ queryKey: ['activity'] });
      },
    },
  );
  return { updateTask, updateTaskAsync, ...rest };
};

export const useUpdateAppointment = () => {
  const queryClient = useQueryClient();
  const {
    mutate: updateAppointment,
    mutateAsync: updateAppointmentAsync,
    ...rest
  } = useMutation(
    (requestBody: UpdateAppointmentRequest) => {
      return httpService.executeLeadEngineCommand({ requestBody, className: 'UpdateAppointmentCommand' });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['user'] });
        queryClient.invalidateQueries({ queryKey: ['agent'] });
        queryClient.invalidateQueries({ queryKey: ['activity'] });
      },
    },
  );
  return { updateAppointment, updateAppointmentAsync, ...rest };
};

export const useUploadDocuments = () => {
  const queryClient = useQueryClient();
  const { mutate: uploadDocuments, ...rest } = useMutation(
    ({ requestBody, files }: { requestBody: UploadDocumentsRequest; files: File[] }) =>
      httpService.executeLeadEngineCommandWithFileUpload({
        requestBody,
        files,
        className: 'UploadDocumentsCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { uploadDocuments, ...rest };
};

export const useModifyDocument = () => {
  const queryClient = useQueryClient();
  const { mutate: modifyDocument, ...rest } = useMutation(
    ({ requestBody, files }: { requestBody: ModifyDocumentRequest; files: File[] }) =>
      httpService.executeLeadEngineCommandWithFileUpload({
        requestBody,
        files,
        className: 'ModifyDocumentCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { modifyDocument, ...rest };
};

export const useDownloadDocumentReference = () => {
  const {
    mutate: downloadDocument,
    mutateAsync: downloadDocumentAsync,
    ...rest
  } = useMutation((requestBody: DownloadDocumentReferenceRequest) =>
    httpService.executeLeadEngineDownloadCommand({
      requestBody,
      className: 'DownloadDocumentReferenceCommand',
    }),
  );
  return { downloadDocument, downloadDocumentAsync, ...rest };
};

export const useDeclineTwilioCall = () => {
  const { mutate: declineTwilioCall, ...rest } = useMutation((requestBody: DeclineTwilioCallRequest) => {
    return httpService.executeLeadEngineCommand({ requestBody, className: 'DeclineTwilioCallCommand' });
  });
  return { declineTwilioCall, ...rest };
};

export const useUpdateCustomDemandFields = () => {
  const queryClient = useQueryClient();
  const { mutate: updateCustomDemandFields, ...rest } = useMutation(
    (requestBody: CompositeCdfCommandRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'CompositeCdfCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['cdf'] });
      },
    },
  );
  return { updateCustomDemandFields, ...rest };
};

export const useUpsertTags = () => {
  const queryClient = useQueryClient();
  const { mutate: upsertTags, ...rest } = useMutation(
    (requestBody: UpsertTagsCommandRequest) => {
      return httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpsertTagsCommand',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['tag'] });
      },
    },
  );
  return { upsertTags, ...rest };
};

export const useLinkPropertyToSellerLead = () => {
  const queryClient = useQueryClient();
  const { mutate: linkPropertyToSellerLead, ...rest } = useMutation(
    (requestBody: LinkPropertyToSellerLeadRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'LinkPropertyToSellerLeadCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { linkPropertyToSellerLead, ...rest };
};

export const useUnlinkPropertyToSellerLead = () => {
  const queryClient = useQueryClient();
  const { mutate: unlinkPropertyToSellerLead, ...rest } = useMutation(
    (requestBody: UnlinkPropertyToSellerLeadRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UnlinkPropertyToSellerLeadCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { unlinkPropertyToSellerLead, ...rest };
};

export const useBulkUpdateLeadStatus = () => {
  const queryClient = useQueryClient();
  const { mutate: bulkUpdateLeadStatus, ...rest } = useMutation(
    (requestBody: BulkUpdateLeadStatusRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'BulkUpdateLeadStatusCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { bulkUpdateLeadStatus, ...rest };
};

export const useUpdateLeadAssignments = () => {
  const queryClient = useQueryClient();
  const { mutate: updateLeadAssignments, ...rest } = useMutation(
    (requestBody: UpdateLeadAssignmentsRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpdateLeadAssignmentsCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { updateLeadAssignments, ...rest };
};

export const useRequestKycCheck = () => {
  const queryClient = useQueryClient();
  const { mutate: requestKycCheck, ...rest } = useMutation(
    (requestBody: RequestKycCheckCommandRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'RequestKycCheckCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { requestKycCheck, ...rest };
};

export const useRerunKycCheck = () => {
  const queryClient = useQueryClient();
  const { mutate: rerunKycCheck, ...rest } = useMutation(
    (requestBody: RerunKycCheckCommandRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'RerunKycCheckCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['kyc'] });
      },
    },
  );
  return { rerunKycCheck, ...rest };
};

export const useGetKycCheck = (
  inputData: GetKycCheckCommandRequest & { kycSearchId: string | null } & { go3Utag: string | null },
) =>
  useQuery({
    queryKey: ['kyc', 'useGetKycCheck', inputData],
    // kycSearchId is not part of the request. It is only used to enable/disable the query
    enabled: !!inputData.contactId && (!!inputData.kycSearchId || !!inputData.go3Utag),
    queryFn: () => {
      return httpService
        .executeLeadEngineCommand({
          requestBody: { contactId: inputData.contactId } as GetKycCheckCommandRequest,
          className: 'GetKycCheckCommand',
        })
        .then((resp) => (resp.data.data as KycCheckResponse) || null);
    },
  });

export const useMergeDuplicateContacts = () => {
  const queryClient = useQueryClient();
  const { mutate: mergeDuplicateContacts, ...rest } = useMutation(
    (requestBody: MergeDuplicateContactsRequest) => {
      return httpService.executeLeadEngineCommand({ requestBody, className: 'MergeDuplicateContactsCommand' });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
        queryClient.invalidateQueries({ queryKey: ['lead'] });
      },
    },
  );
  return { mergeDuplicateContacts, ...rest };
};

export const useUpdateShopEnergyAdvisory = () => {
  const queryClient = useQueryClient();
  const { mutate: updateShopEnergyAdvisory, ...rest } = useMutation(
    (requestBody: UpdateShopEnergyAdvisoryRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UpdateShopEnergyAdvisoryCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['shop'] });
      },
    },
  );
  return { updateShopEnergyAdvisory, ...rest };
};

export const useCreateManualConsent = () => {
  const queryClient = useQueryClient();
  const { mutate: createManualConsent, ...rest } = useMutation(
    ({ requestBody, files }: { requestBody: CreateManualConsentRequest; files: File[] }) =>
      httpService.executeLeadEngineCommandWithFileUpload({
        requestBody,
        files,
        className: 'CreateManualConsentCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { createManualConsent, ...rest };
};

export const useRequestConsent = () => {
  const queryClient = useQueryClient();
  const { mutate: requestConsent, ...rest } = useMutation(
    ({ requestBody }: { requestBody: RequestConsentRequest }) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'RequestConsentViaMailCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { requestConsent, ...rest };
};

export const useUpdateLeadExtendingInteractions = () => {
  const queryClient = useQueryClient();
  const { mutate: updateLeadExtendingInteractions, ...rest } = useMutation(
    (requestBody: UpdateLeadExtendingInteractionsRequest) => {
      return httpService.executeLeadEngineCommand({ requestBody, className: 'UpdateLeadExtendingInteractionsCommand' });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['shop'] });
      },
    },
  );
  return { updateLeadExtendingInteractions, ...rest };
};

export const useLinkLeads = () => {
  const queryClient = useQueryClient();
  const { mutate: linkLeads, ...rest } = useMutation(
    (requestBody: LinkLeadsRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'LinkLeadsCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { linkLeads, ...rest };
};

export const useUnlinkLeads = () => {
  const queryClient = useQueryClient();
  const { mutate: unlinkLeads, ...rest } = useMutation(
    (requestBody: UnlinkLeadsRequest) =>
      httpService.executeLeadEngineCommand({
        requestBody,
        className: 'UnlinkLeadsCommand',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['lead'] });
        queryClient.invalidateQueries({ queryKey: ['contact'] });
      },
    },
  );
  return { unlinkLeads, ...rest };
};
