import { AppointmentDrawerData, useActiveShopId, useSnackBars } from '@ev/eva-container-api';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCurrentAgentId } from 'api/graphql/hooks/useCurrentAgent';
import { queryClient } from 'api/queryClient';
import { useCreateAppointment, useUpdateAppointment } from 'api/rest';
import { CreateAppointmentRequest } from 'api/rest/models/rest/Command';
import { CreateOrEditAppointmentForm } from 'components/dashboard/CreateOrEditAppointmentForm';
import { EVDrawerContent } from 'components/general/EVDrawer/EVDrawerContent';
import { separateContactsAndLeads } from 'page-components/dashboard/separateContactsAndLeads';
import { FormProvider, useForm } from 'react-hook-form';
import { useGetAppointmentDefaultValues } from 'util/defaultValues/appointmentDefaultValues';
import { UNASSIGNED } from 'util/emptyDataUtils';
import { useTranslation } from 'util/i18next';
import { useCreateNewAppointmentSchema } from 'util/schemas/newAppointmentSchema';
import { useErrorSnackBar } from 'util/useErrorSnackBar';
import { useSelectedGoogleIntegration } from 'util/useSelectedGoogleIntegration';
import { z } from 'zod';

export interface CreateOrEditAppointmentProps {
  onClose: () => void;
  initialAppointmentData: AppointmentDrawerData;
}

export function CreateOrEditAppointment({ onClose, initialAppointmentData }: CreateOrEditAppointmentProps) {
  const { t } = useTranslation(['user']);
  const { createAppointment, isLoading: isCreateLoading } = useCreateAppointment();
  const { updateAppointment, isLoading: isUpdateLoading } = useUpdateAppointment();
  const { openSnackBar } = useSnackBars();
  const { openErrorSnackBar } = useErrorSnackBar();
  const currentAgentId = useCurrentAgentId();
  const activeShopId = useActiveShopId();
  const { hasActiveGoogleAccountIntegration } = useSelectedGoogleIntegration();
  const getAppointmentDefaultValues = useGetAppointmentDefaultValues();

  const createNewAppointmentSchema = useCreateNewAppointmentSchema();
  const methods = useForm<z.infer<typeof createNewAppointmentSchema>>({
    mode: 'onTouched',
    resolver: zodResolver(createNewAppointmentSchema),
    defaultValues: () =>
      getAppointmentDefaultValues({
        currentAgentId,
        activeShopId,
        initialAppointmentData,
      }),
  });

  const isCompletedOrCancelled = ['COMPLETED', 'CANCELLED'].includes(methods.formState.defaultValues?.status ?? '');
  const editId = initialAppointmentData.editId;
  const drawerTitle = (() => {
    if (isCompletedOrCancelled) {
      return t('user:dashboard.appointment.completed.drawerTitle');
    }
    if (editId) {
      return t('user:dashboard.appointment.edit.drawerTitle');
    }
    return t('user:dashboard.appointment.add.drawerTitle');
  })();

  const onSave = (data: z.infer<typeof createNewAppointmentSchema>): void => {
    const payload = mapAppointmentData(data, activeShopId!);
    if (editId) {
      updateAppointment(
        { ...payload, appointmentId: editId },
        {
          onSuccess: () => {
            openSnackBar(t('user:dashboard.updateActivity.appointment.success'), 'success');
            queryClient.invalidateQueries({ queryKey: ['agent'] });
            onClose();
          },
          onError: (error) => openErrorSnackBar(t('user:dashboard.updateActivity.appointment.error'), error),
        },
      );
    } else {
      createAppointment(payload, {
        onSuccess: () => {
          openSnackBar(t('user:dashboard.addActivity.appointment.success'), 'success');
          queryClient.invalidateQueries({ queryKey: ['agent'] });
          onClose();
        },
        onError: (error) => openErrorSnackBar(t('user:dashboard.addActivity.appointment.error'), error),
      });
    }
  };

  if (methods.formState.isLoading) {
    return null;
  }

  return (
    <EVDrawerContent
      title={drawerTitle}
      onClose={onClose}
      {...(!isCompletedOrCancelled
        ? {
            primaryAction: {
              primaryButtonLabel: t('user:dashboard.drawer.addActivity.saveButton'),
              callback: methods.handleSubmit(onSave),
              disabled: !hasActiveGoogleAccountIntegration,
            },
          }
        : {})}
      isLoading={isCreateLoading || isUpdateLoading}
    >
      <FormProvider {...methods}>
        <CreateOrEditAppointmentForm />
      </FormProvider>
    </EVDrawerContent>
  );
}

export function mapAppointmentData(
  data: z.infer<ReturnType<typeof useCreateNewAppointmentSchema>>,
  shopId: string,
): CreateAppointmentRequest {
  const startTime = new Date(data.date);
  const endTime = new Date(data.date);
  startTime.setHours(data.startTime.getHours());
  startTime.setMinutes(data.startTime.getMinutes());
  endTime.setHours(data.endTime.getHours());
  endTime.setMinutes(data.endTime.getMinutes());

  const contactsAndLeads = separateContactsAndLeads(data.contactsAndLeads);

  return {
    shopId,
    agentId: data.agentId,
    teamId: data.teamId !== UNASSIGNED ? data.teamId : undefined,
    type: data.actionType,
    status: data.status,
    subject: data.subject,
    endDateTime: endTime.toISOString(),
    startDateTime: startTime.toISOString(),
    comment: data.comment,
    contactIds: contactsAndLeads.contactIds,
    leadIds: contactsAndLeads.leadIds,
    feedback: data.feedback,
    additionalAgentIds: data.additionalAgents?.map((agent) => agent.id),
    ...(data.actionType === 'VIEWING' && { feedback: data.feedback }),
    ...(data.properties?.length && { linkedPropertyIds: data.properties.map((p) => p.utag) }),
  };
}
