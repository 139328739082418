import { DRAWER_Z_INDEX, useDrawer, useSnackBars } from '@ev/eva-container-api';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { BaseActivity, Lead } from 'api/graphql/generated/graphql';
import { useContact } from 'api/graphql/hooks/useContact';
import { useFullLeads } from 'api/graphql/hooks/useFullLeads';
import { useShops } from 'api/graphql/hooks/useShops';
import { useUpdateCallActivity } from 'api/rest/hooks/useActivity';
import { ActiveCallPage } from 'components/calls/ActiveCallPopover/ActiveCallPopover';
import { CallSubpageHeader } from 'components/calls/ActiveCallPopover/CallSubpageHeader';
import { SearchResultCard } from 'components/calls/ActiveCallPopover/SearchResultCard';
import { CallFrame } from 'components/calls/CallFrame';
import { EVDrawer } from 'components/general/EVDrawer/EVDrawer';
import { IntentTag } from 'components/general/IntentTag/IntentTag';
import { SearchTextField } from 'components/general/SearchTextField/SearchTextField';
import LeadsIcon from 'components/icons/lead_hub_logo.svg?react';
import LinkIcon from 'components/icons/link_external.svg?react';
import PlusIcon from 'components/icons/plus_naked.svg?react';
import { AddNewLead } from 'components/leads/forms/AddNewLead';
import { useActiveShopId } from 'components/state/ActiveShopProvider';
import { CallOutcome } from 'components/state/Twilio';
import { filterLeads } from 'page-components/mails/ThreadPage/LinkingLeadsSearch';
import { ChangeEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'theme';
import { useTranslation } from 'util/i18next';
import { intentTypeToIntentTabs } from 'util/intentType';
import { useDestination } from 'util/navigation/useDestination';
import { leadString } from 'util/stringUtils';
import { useCreateShortLeadSummary } from 'util/summaries/leadSummary';
import { useErrorSnackBar } from 'util/useErrorSnackBar';
import { usePermissions } from 'util/usePermissions';

export function LinkLeadToCallPage({
  onClose,
  headerTitle,
  headerColor,
  activity,
  onPageChange,
  callDuration,
}: {
  headerTitle: string;
  headerColor: string;
  onClose: () => void;
  onPageChange: (page: ActiveCallPage) => void;
  activity: BaseActivity;
  callDuration: number;
}) {
  const { t } = useTranslation(['communication']);
  const [searchText, setSearchText] = useState('');
  const { openSnackBar } = useSnackBars();
  const { openErrorSnackBar } = useErrorSnackBar();
  const [isCreateLeadDrawerOpen, setCreateLeadDrawerOpen] = useDrawer('createLeadForCall');
  const { canCreateLead } = usePermissions();
  const { setActiveShopId } = useActiveShopId();

  const { updateCallActivity } = useUpdateCallActivity();

  const { leads, isInitialLoading: isInitialLoadingLeads } = useFullLeads(
    {
      where: {
        contactId: { _eq: activity.contactId },
        status: { _eq: 'ACTIVE' },
      },
    },
    { enabled: !!activity.contactId },
  );
  const filteredLeads = filterLeads({ leads: leads || [], searchText });

  const { contact } = useContact({ id: activity.contactId }, { enabled: !!activity.contactId });
  const { shops } = useShops({ where: { id: { _eq: activity.shopId || '' } } }, { enabled: !!activity.shopId });

  const activityShop = shops?.[0];

  const handleLinkLead = (leadId: string | undefined) => {
    const call = activity?.callActivity?.call;
    if (!call) {
      return;
    }
    updateCallActivity(
      {
        requestBody: {
          callOutcome: (call.outcome as CallOutcome) || undefined,
          callNote: call.note || undefined,
          leadId,
          callSid: call.callSid,
        },
      },
      {
        onSuccess: () => {
          openSnackBar(t('communication:call.activeCall.linkLeadToCallSuccess'), 'success');
          onPageChange('overview');
        },
        onError: (error) => openErrorSnackBar(t('communication:call.activeCall.linkLeadToCallError'), error),
      },
    );
  };

  const handleCreateNewLead = () => {
    if (!activity.shopId) {
      return;
    }
    setActiveShopId(activity.shopId);
    setCreateLeadDrawerOpen(true);
  };

  function renderContent() {
    if (isInitialLoadingLeads) {
      return null;
    }
    if (leads && leads.length === 0) {
      return <NoLeadsPlaceholder onClick={handleCreateNewLead} />;
    } else {
      return (
        <>
          <Typography variant="h2" sx={{ marginTop: 4, marginBottom: 2, textAlign: 'center' }}>
            {t('communication:call.activeCall.linkLeadTitle')}
          </Typography>
          <SearchTextField
            fullWidth={true}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setSearchText(event.target.value);
            }}
            size="medium"
            value={searchText}
            placeholder={t('communication:call.activeCall.searchForALead')}
          />
          <Box
            sx={{
              overflow: 'auto',
              flex: 'auto',
              backgroundColor: theme.palette.backgroundExtension.grey1,
            }}
          >
            <CreateLeadCard onClick={handleCreateNewLead} disabled={!activityShop || !canCreateLead(activityShop)} />
            {filteredLeads.map((lead) => (
              <LinkingLeadCard
                key={lead.id}
                lead={lead}
                onLink={handleLinkLead}
                isLinked={activity.lead?.id === lead.id}
              />
            ))}
          </Box>
        </>
      );
    }
  }

  return (
    <>
      <CallFrame
        onClose={onClose}
        title={headerTitle}
        headerColor={headerColor}
        variant="light"
        isLoading={isInitialLoadingLeads}
        sx={isCreateLeadDrawerOpen ? { backgroundColor: theme.palette.backgroundExtension.grey1 } : undefined}
      >
        {isCreateLeadDrawerOpen ? (
          <CompleteLeadCreationPlaceholder />
        ) : (
          <>
            <CallSubpageHeader onPageChange={onPageChange} callDuration={callDuration} />
            {renderContent()}
          </>
        )}
      </CallFrame>

      <EVDrawer
        zIndex={DRAWER_Z_INDEX + 1}
        isOpen={isCreateLeadDrawerOpen}
        onClose={() => setCreateLeadDrawerOpen(false)}
      >
        {contact && (
          <AddNewLead
            onSuccess={handleLinkLead}
            contact={contact}
            onClose={() => setCreateLeadDrawerOpen(false)}
            redirectToLeadOnSuccess={false}
          />
        )}
      </EVDrawer>
    </>
  );
}

export function CreateLeadCard({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
  const { t } = useTranslation(['communication']);

  return (
    <SearchResultCard
      onClick={onClick}
      disabled={disabled}
      avatar={<PlusIcon color={theme.palette.shade.grey3} />}
      title={<Typography variant="body3">{t('communication:call.activeCall.createNewLead')}</Typography>}
    />
  );
}

export function LinkingLeadCard({
  lead,
  onLink,
  isLinked,
}: {
  lead: Lead;
  isLinked: boolean;
  onLink: (leadId: string | undefined) => void;
}) {
  const { t } = useTranslation(['lead', 'enums']);
  const leadSummary = useCreateShortLeadSummary(lead);

  const { toLeadDetails } = useDestination();
  const { canEditLead } = usePermissions();

  return (
    <SearchResultCard
      isSelected={isLinked}
      onClick={() => onLink(isLinked ? undefined : lead.id)}
      disabled={!canEditLead(lead)}
      avatar={
        <IntentTag
          intent={intentTypeToIntentTabs(lead.intentType)}
          compact={true}
          sx={{
            margin: 0,
            fontSize: theme.typography.body3.fontSize,
            width: '28px',
          }}
        />
      }
      title={
        <Stack direction="row" alignItems="center">
          <Typography variant="body3" component="span" sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {leadString(lead, t)}
          </Typography>
          <Link
            to={toLeadDetails({
              shopId: lead.shopId,
              contactId: lead.contactId,
              leadId: lead.id,
            })}
            onClick={(event) => event.stopPropagation()}
            target="_blank"
          >
            <IconButton color="secondary">
              <LinkIcon />
            </IconButton>
          </Link>
        </Stack>
      }
    >
      <Typography
        sx={{ gridColumn: 2, textOverflow: 'ellipsis', overflow: 'hidden' }}
        variant="body4"
        color="text.secondary"
      >
        {leadSummary}
      </Typography>
    </SearchResultCard>
  );
}

export function NoLeadsPlaceholder({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation(['communication']);

  return (
    <Stack alignItems="center" sx={{ marginY: 'auto' }}>
      <Box
        sx={{
          padding: 2,
          borderRadius: '50%',
          backgroundColor: theme.palette.shade.grey4,
          marginBottom: 3,
        }}
      >
        <LeadsIcon height="48" width="48" viewBox="0 0 24 24" style={{ display: 'block' }} />
      </Box>
      <Typography variant="h2">{t('communication:call.activeCall.noLeads.title')}</Typography>
      <Typography
        variant="body4"
        color="text.secondary"
        sx={{ marginBottom: 2, maxWidth: '220px', textAlign: 'center' }}
      >
        {t('communication:call.activeCall.noLeads.subtitle')}
      </Typography>
      <Button variant="outlined" color="secondary" startIcon={<PlusIcon />} onClick={onClick}>
        {t('communication:call.activeCall.noLeads.addLeadButton')}
      </Button>
    </Stack>
  );
}

export function CompleteLeadCreationPlaceholder() {
  const { t } = useTranslation(['communication']);
  return (
    <Typography variant="h2" color={theme.palette.text.disabled} sx={{ margin: 'auto', textAlign: 'center' }}>
      {t('communication:call.activeCall.completeLeadCreationPlaceholder')}
    </Typography>
  );
}
