import { datadogRum } from '@datadog/browser-rum';
import { RouterErrorBoundary } from 'components/error/RouterErrorBoundary';
import Page404 from 'eva-frame/404';
import { EvaLayout, EvaPublicLayout } from 'eva-frame/EvaLayout';
import { EvaProviderWithShop } from 'eva-frame/EvaProviders/EvaProviderWithShop';
import { useIsFeatureEnabled } from 'eva-frame/EvaProviders/FeatureFlagProvider';
import { filterOverlappingRoutes, getPathPrefixes } from 'eva-frame/filterOverlappingRoutes';
import { NetworkPage } from 'page-components/network';
import { leadHubRoutes } from 'page-components/routes';
import { useEffect, useState } from 'react';
import { Outlet, RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { SUPPORTED_LANGUAGES } from 'util/i18next';
import { loadAppModules } from 'util/useAppModules';

type Router = ReturnType<typeof createBrowserRouter>;

function catchImportError<T>(importPromise: Promise<T>): Promise<T> {
  return importPromise.catch((e) => {
    // eslint-disable-next-line no-console
    console.error('Dynamic import error:', e);
    datadogRum.addError(e);
    throw e;
  });
}

function wrapLazyLoadingWithErrorLogger(routes: RouteObject[]) {
  for (const route of routes) {
    if (route.lazy) {
      const lazy = route.lazy;
      route.lazy = () => catchImportError(lazy());
    }
    if (route.children) {
      wrapLazyLoadingWithErrorLogger(route.children);
    }
  }
}

async function setupRouter({
  integrateKyc,
  integrateGome,
  integrateProfile,
  enableAppModules,
}: {
  integrateKyc: boolean;
  integrateGome: boolean;
  integrateProfile: boolean;
  enableAppModules: boolean;
}) {
  const appModules = enableAppModules
    ? await loadAppModules({
        integrateKyc,
        integrateGome,
        integrateProfile,
      })
    : [];

  const shopRoutes: RouteObject[] = [...leadHubRoutes(appModules)];

  const routeModules = [
    ...(integrateKyc ? [{ name: 'kyc', load: () => import('kyc/routes') }] : []),
    ...(integrateGome ? [{ name: 'gome', load: () => import('gome/routes') }] : []),
    ...(integrateProfile ? [{ name: 'profile', load: () => import('profile/routes') }] : []),
  ] as const;

  await Promise.all(
    routeModules.map(async (module) => {
      try {
        const routes = (await module.load()).default as RouteObject[];
        const excludedRoutePrefixes = getPathPrefixes(shopRoutes);
        const nonOverlappingRoutes = filterOverlappingRoutes(routes, excludedRoutePrefixes);
        shopRoutes.push(...nonOverlappingRoutes);
      } catch (error) {
        // We don't log to sentry because it should not trigger errors if another platform is down
        // eslint-disable-next-line no-console
        console.error(`Unable to load ${module.name}`, error);
      }
    }),
  );

  wrapLazyLoadingWithErrorLogger(shopRoutes);

  return createBrowserRouter(
    createLanguageRoutes([
      {
        path: 'network',
        element: (
          <EvaPublicLayout>
            <NetworkPage isPublic />
          </EvaPublicLayout>
        ),
        errorElement: <RouterErrorBoundary />,
      },
      {
        path: '404',
        element: <Page404 />,
      },
      {
        path: ':shopId?',
        element: (
          <EvaProviderWithShop>
            <EvaLayout>
              <Outlet />
            </EvaLayout>
          </EvaProviderWithShop>
        ),
        children: shopRoutes,
        errorElement: <RouterErrorBoundary />,
      },
      {
        path: '*',
        element: <Page404 />,
      },
    ]),
  );
}

function createLanguageRoutes(children: RouteObject[]): RouteObject[] {
  return [
    ...SUPPORTED_LANGUAGES.map((lang) => ({
      path: lang,
      children,
    })),
    ...children,
  ];
}

export function EvaRouter() {
  const [router, setRouter] = useState<Router>();
  const integrateKyc = useIsFeatureEnabled('KYC_INTEGRATION');
  const integrateProfile = useIsFeatureEnabled('PROFILE_INTEGRATION');
  const integrateGome = useIsFeatureEnabled('GOME_MODULE_FEDERATION');
  const enableAppModules = useIsFeatureEnabled('APP_MODULES');

  useEffect(() => {
    setupRouter({ integrateKyc, integrateGome, integrateProfile, enableAppModules }).then(setRouter);
  }, [integrateKyc, integrateGome, integrateProfile, enableAppModules]);

  if (!router) {
    return null;
  }

  return <RouterProvider router={router} />;
}
