import { SendEmailRequest } from 'api/rest/hooks/useSendEmail';
import { isSet } from 'util/filters';
import { EmailFormData, Recipient } from 'util/schemas/sendEmailSchema';
import { CcRecipient } from 'util/schemas/useCcAndBccSchema';

export function recipientMapper(participant: Recipient | CcRecipient) {
  switch (participant.type) {
    case 'AGENT':
      return {
        agentId: participant.id,
        type: 'SendEmailFeaturesAgent',
      };
    case 'CONTACT':
      return {
        contactId: participant.id,
        email: 'email' in participant ? participant.email : undefined,
        type: 'SendEmailFeaturesContact',
      };
    case 'RELATED_CONTACT':
      return {
        contactId: participant.id,
        type: 'SendEmailFeaturesContact',
      };
    case 'EMAIL':
      return {
        email: participant.email,
        type: 'SendEmailFeaturesEmail',
      };
    case 'LEAD':
      return {
        leadId: participant.id,
        type: 'SendEmailFeaturesLead',
      };
  }
}

export function sendPopoverEmailMapper(
  emailData: EmailFormData,
  trackingDisabled: boolean,
  files: File[],
  shopId: string,
): SendEmailRequest {
  return {
    data: {
      shopId,
      emailTemplateId: emailData.templateId,
      email: {
        from: emailData.from,
        to: emailData.to.map((to) => recipientMapper(to)),
        cc: emailData.cc.map((cc) => recipientMapper(cc)),
        bcc: emailData.bcc.map((bcc) => recipientMapper(bcc)),
        subject: emailData.subject,
        body: emailData.body,
        replyToMessageId: emailData.replyToMessageId,
      },
      go3PropertyUtags: emailData.properties.map((p) => p.utag).filter(isSet),
      sharedLeadId: emailData.sharedLeadId,
      trackingDisabled,
    },
    files,
  };
}
