import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CrossNakedThicker from 'components/icons/cross_naked.svg?react';
import Maximize from 'components/icons/maximize.svg?react';
import Minimize from 'components/icons/minimize.svg?react';
import { theme } from 'theme';

export const HEADER_HEIGHT = 40;
export const PopoverHeader = ({
  isMinimized,
  setIsMinimized,
  onClose,
  backgroundColor,
  title,
  secondaryTitle,
}: {
  isMinimized: boolean;
  setIsMinimized: (isMinimized: boolean) => void;
  onClose: () => void;
  backgroundColor?: string;
  title: string;
  secondaryTitle?: string;
}) => {
  const fontColor = backgroundColor ? theme.palette.textExtension.white : theme.palette.text.primary;

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: secondaryTitle ? '1fr 2fr 1fr' : '1fr max-content',
        alignItems: 'center',
        backgroundColor: backgroundColor || theme.palette.textExtension.white,
        height: HEADER_HEIGHT,
        paddingX: 2,
        borderBottom: `1px solid ${theme.palette.shade.grey4}`,
        cursor: isMinimized ? 'pointer' : 'auto',
      }}
      component="header"
      onClick={() => isMinimized && setIsMinimized(!isMinimized)}
    >
      <Typography
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        variant="body1"
        color={fontColor}
      >
        {title}
      </Typography>

      {secondaryTitle && (
        <Typography sx={{ justifySelf: 'center', whiteSpace: 'nowrap' }} variant="body3" color={fontColor}>
          {secondaryTitle}
        </Typography>
      )}
      <Stack direction="row" sx={{ justifySelf: 'flex-end' }}>
        <IconButton size="small" onClick={() => setIsMinimized(!isMinimized)}>
          {isMinimized ? <Maximize color={fontColor} /> : <Minimize color={fontColor} />}
        </IconButton>
        <IconButton size="small" onClick={onClose} edge="end" data-testid="cancel">
          <CrossNakedThicker color={fontColor} />
        </IconButton>
      </Stack>
    </Box>
  );
};
