import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { InternalPreviewBulkEmailStepProps } from 'components/emails/SendBulkEmailDialog/PreviewBulkEmailStep/PreviewBulkEmailStep';
import { PreviewBulkEmailTemplateForm } from 'components/emails/SendBulkEmailDialog/PreviewBulkEmailStep/PreviewBulkEmailTemplateForm';
import { RecipientPagination } from 'components/emails/SendBulkEmailDialog/PreviewBulkEmailStep/RecipientPagination';
import { SendBulkEmailDialogHeader } from 'components/emails/SendBulkEmailDialog/SendBulkEmailDialogHeader';
import { ModalContainer, ModalFooter, ModalHeaderMobile, ModalTitle, ModalTitleAction } from 'components/general/Modal';
import ArrowLeftIcon from 'components/icons/arrow_2_left.svg?react';
import Minimize from 'components/icons/minimize.svg?react';
import TrashIcon from 'components/icons/trash.svg?react';
import { usePopover } from 'components/state/UIStateProvider';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'util/i18next';
import { PreviewBulkEmailFormData } from 'util/schemas/sendBulkEmailSchema';

export function PreviewBulkEmailStepMobile({
  possibleFromEmails,
  onSend,
  onBackToCompose,
  noEmailIntegration,
  selectedRecipient,
  onSelectRecipient,
  onRemoveRecipient,
  from,
  onChangeFrom,
  hidePrice,
  isSending,
}: InternalPreviewBulkEmailStepProps) {
  const { t } = useTranslation(['communication']);
  const { watch } = useFormContext<PreviewBulkEmailFormData>();
  const { isMinimized, setIsMinimized } = usePopover('emailBulk');

  return (
    <>
      <ModalHeaderMobile>
        <Box sx={{ display: 'flex' }}>
          <ModalTitleAction
            onClick={() => {
              setIsMinimized(!isMinimized);
            }}
          >
            <Minimize />
          </ModalTitleAction>
          <ModalTitleAction onClick={onBackToCompose} disabled={isSending}>
            <ArrowLeftIcon />
          </ModalTitleAction>
        </Box>
        <ModalTitle>{t('communication:sendBulkEmailDialog.titlePreviewMobile')}</ModalTitle>
        <Button
          variant="contained"
          color="primary"
          onClick={onSend}
          disabled={noEmailIntegration || isSending}
          sx={{ whiteSpace: 'nowrap' }}
        >
          {t('communication:sendBulkEmailDialog.sendButtonShort', { number: watch('recipients').length })}
          {isSending && <CircularProgress size={14} sx={{ marginLeft: 1, color: 'inherit' }} />}
        </Button>
      </ModalHeaderMobile>

      <SendBulkEmailDialogHeader
        from={from}
        onChangeFrom={onChangeFrom}
        noEmailIntegration={noEmailIntegration}
        possibleFromEmails={possibleFromEmails}
      />

      <ModalContainer singleColumn sx={{ opacity: noEmailIntegration ? 0.2 : undefined }}>
        <PreviewBulkEmailTemplateForm
          selectedRecipient={selectedRecipient}
          disabled={noEmailIntegration}
          showFullRecipient
          hidePrice={hidePrice}
        />
      </ModalContainer>
      <ModalFooter>
        {selectedRecipient && (
          <IconButton
            sx={{ marginRight: 'auto' }}
            disabled={noEmailIntegration || isSending}
            onClick={() => onRemoveRecipient(selectedRecipient)}
          >
            <TrashIcon />
          </IconButton>
        )}
        <RecipientPagination
          disabled={noEmailIntegration}
          recipients={watch('recipients').map((r) => r.recipient)}
          selectedRecipient={selectedRecipient}
          onSelectRecipient={onSelectRecipient}
        />
      </ModalFooter>
    </>
  );
}
