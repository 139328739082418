import { Button } from '@mui/material';
import { MouseEventHandler, ReactNode } from 'react';

export function TooltipButton({
  children,
  onClick,
  onMouseDown,
  icon,
  disabled,
}: {
  children: ReactNode;
  icon: ReactNode;
  onClick?: MouseEventHandler;
  onMouseDown?: MouseEventHandler;
  disabled?: boolean;
}) {
  return (
    <Button
      sx={{ justifyContent: 'flex-start' }}
      color="secondary"
      startIcon={icon}
      onClick={onClick}
      disabled={disabled}
      onMouseDown={onMouseDown}
    >
      {children}
    </Button>
  );
}
