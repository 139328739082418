import {
  DRAWER_Z_INDEX,
  FRAME_Z_INDEX,
  MODAL_Z_INDEX,
  NOTIFICATION_Z_INDEX,
  TOOLTIP_Z_INDEX,
} from '@ev/eva-container-api';
import { SxProps, createTheme } from '@mui/material/styles';
import ArrowDownIcon from 'components/icons/arrow_1_down.svg?react';
import CrossIcon from 'components/icons/cross_naked.svg?react';
import React from 'react';
import { breakpoints } from './breakpoints';
import { EVPalette, palette } from './palette';
import { typography } from './typography';

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  interface Palette extends EVPalette {}
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  interface PaletteOptions extends EVPalette {}

  interface ChipVariants {
    inactive: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface ChipVariantsOptions {
    inactive?: React.CSSProperties;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    inactive: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    // removed breakpoints
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;

    // added breakpoints
    mobile: true;
    tablet: true;
    desktop: true;
    'large-desktop': true;
    'extra-large-desktop': true;
  }
}

export const theme = createTheme({
  zIndex: {
    mobileStepper: 100, // unused
    fab: 100, // unused
    speedDial: 100, // unused
    appBar: FRAME_Z_INDEX,
    drawer: DRAWER_Z_INDEX,
    modal: MODAL_Z_INDEX,
    snackbar: NOTIFICATION_Z_INDEX,
    tooltip: TOOLTIP_Z_INDEX,
  },
  breakpoints: {
    ...breakpoints,
  },
  typography: {
    ...typography,
  },
  palette,
});

/* MUI Reference https://mui.com/customization/theme-components/#main-content */
theme.components = {
  MuiChip: {
    defaultProps: {
      deleteIcon: <CrossIcon />,
    },
    variants: [
      {
        props: { variant: 'filled' },
        style: {
          backgroundColor: theme.palette.shade.grey1,
          color: 'white',
          '&&:hover': {
            backgroundColor: theme.palette.shade.grey1,
            opacity: 0.9,
          },

          '& .MuiChip-deleteIcon': {
            color: theme.palette.text.primary,
          },
        },
      },
      {
        props: { variant: 'inactive' },
        style: {
          color: theme.palette.shade.grey1,
        },
      },
    ],
  },

  MuiCssBaseline: {
    styleOverrides: `
      @font-face {
        font-family: 'EngelVoelkersText';
        font-style: normal;
        font-display: swap;
        font-weight: 400 700;
        src: url('/fonts/EngelVoelkersText_W_Wght.woff2') format('woff2');
      }
      
      @font-face {
        font-family: 'EngelVoelkersHead';
        font-style: normal;
        font-display: swap;
        font-weight: 700;
        src: url('/fonts/EngelVoelkersHead_W_Wght.woff2') format('woff2');
      }
      
      html,
      body {
        padding: 0;
        margin: 0;
      }
      
      body {
        overflow-x: hidden;
        background-color: #fafafa;
      }
      
      a {
        color: inherit;
        text-decoration: none;
      }
      
      * {
        box-sizing: border-box;
      }
      
      svg {
        vertical-align: middle;
      }
    `,
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      disableFocusRipple: true,
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
      text: {
        padding: theme.spacing(1),
        border: `2px solid ${theme.palette.transparent.full}`,
        '&:focus': {
          background: 'none',
          borderColor: theme.palette.text.secondary,
        },
      },
      endIcon: {
        marginRight: theme.spacing(-1),
        '& > *:nth-of-type(1)': {
          fontSize: 'inherit',
        },
      },
    },
  },
  MuiIconButton: {
    defaultProps: {
      disableFocusRipple: true,
    },
    styleOverrides: {
      root: {
        padding: theme.spacing(0.75),
        border: `2px solid ${theme.palette.transparent.full}`,
        '&:focus': {
          background: 'none',
        },
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        maxHeight: '48px',
        minHeight: '48px',
        '&.Mui-expanded': {
          maxHeight: '48px',
          minHeight: '48px',
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        fontSize: 14,
        '&.Mui-checked': {
          color: theme.palette.shade.grey6,
        },
        '&.Mui-disabled': {
          color: theme.palette.shade.grey6,
          opacity: 0.5,
        },
      },
      colorSecondary: {
        color: theme.palette.shade.grey3,

        '&.Mui-checked': {
          color: theme.palette.shade.grey1,
        },
        '&.Mui-disabled': {
          color: theme.palette.shade.grey1,
          opacity: 0.5,
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        '&.Mui-focused': {
          color: theme.palette.shade.grey6,
        },
        '.MuiFormLabel-asterisk': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        fontSize: 14,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: 16,
        top: -4,
        '.MuiInputLabel-asterisk': {
          color: theme.palette.primary.main,
        },
        '&.Mui-focused': {
          color: theme.palette.shade.grey6,
        },
      },
      shrink: {
        top: 0,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        padding: 12,
        //Keeps the mui-tel flag icon from becoming too tall
        '.MuiInputAdornment-root': {
          height: 0,
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        '& input': {
          fontSize: 16,
          padding: 0,
          textOverflow: 'ellipsis',
        },
        '&::before': {
          borderBottom: '10px solid red',
        },
        width: '100%',
        color: theme.palette.primary.main,
        '& input::before': {
          borderBottom: '10px solid red',
          color: theme.palette.primary.main,
        },
        '& input::after': {
          color: theme.palette.primary.main,
          borderBottom: '10px solid red',
        },
        '& label.Mui-focused': {
          color: theme.palette.shade.grey6,
        },
        /** Hide number increment controls */
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
          display: 'none',
        },
        '& input[type=number]': {
          mozAppearance: 'textfield',
        },
        '& textarea': {
          height: 24,
        },
      },
    },
    variants: [
      {
        props: { multiline: true },
        style: {
          '.MuiOutlinedInput-root': {
            padding: `9.5px ${theme.spacing(1.5)}`,
          },
          '& textarea.MuiOutlinedInput-input': {
            height: 12,
            fontSize: 16,
          },
        },
      },
    ],
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '& input': {
          height: theme.spacing(3),
          fontSize: 16,
          letterSpacing: 0.03,
        },
        '& .MuiSelect-outlined.MuiSelect-select': {
          minHeight: theme.spacing(3),
        },
        borderRadius: 2,
        backgroundColor: theme.palette.backgroundExtension.white,
        '& fieldset': {
          fontSize: 16,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.shade.grey6,
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontSize: theme.typography.body4.fontSize,
        minHeight: '48px',
        '&.Mui-selected': {
          backgroundColor: theme.palette.shade.grey4,
        },
        '&.Mui-selected:hover': {
          backgroundColor: theme.palette.transparent.black8,
        },
        '&.Mui-selected:focus': {
          backgroundColor: theme.palette.transparent.black8,
        },
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: theme.palette.shade.grey2,
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
        '& .MuiTypography-root': {
          fontSize: theme.typography.body3.fontSize,
        },
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      IconComponent: ArrowDownIcon,
    },
    variants: [
      {
        props: { variant: 'outlined' },
        style: {
          '& .MuiSelect-outlined': {
            padding: `0 ${theme.spacing(5.25)} 0 0`,
          },
          '& .MuiSelect-icon': {
            top: 'unset',
            right: theme.spacing(1.5),
          },
          width: '100%',
          borderRadius: 2,
          fontSize: 16,
          '& li': {
            fontSize: 14,
          },
        },
      },
    ],
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        '&.Mui-checked': {
          color: theme.palette.shade.grey6,
        },
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        width: '100%',
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        marginLeft: 0,
        color: theme.palette.error.main,
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        backgroundColor: theme.palette.shade.grey5,
        boxSizing: 'border-box',
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        padding: '0',
        textAlign: 'center',
        fontSize: theme.typography.h1.fontSize,
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.backgroundExtension.white,
        margin: '32px 0 56px',
        padding: '24px',

        '.MuiDialogTitle-root+&': { padding: '24px' },
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: '0',
        '&>:not(:first-of-type)': {
          marginLeft: '16px',
        },
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: theme.palette.shade.grey4,
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.shade.grey4,
        },
        '&.Mui-selected:hover': {
          backgroundColor: theme.palette.transparent.black8,
        },
      },
    },
  },
  MuiAutocomplete: {
    defaultProps: {
      popupIcon: <ArrowDownIcon />,
      limitTags: 1,
    },
    styleOverrides: {
      root: {
        '& .MuiAutocomplete-tag': {
          fontSize: 12,
          margin: `-1px 0`,
        },
        '& span.MuiAutocomplete-tag': {
          /** styling for limitTags text */
          backgroundColor: theme.palette.shade.grey4,
          padding: `0 ${theme.spacing(1)}`,
          borderRadius: theme.spacing(2),
          cursor: 'pointer',
        },
        '.MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
          right: theme.spacing(1.5),
          '& .MuiIconButton-root:hover': {
            background: 'unset',
          },
        },
        '.MuiOutlinedInput-root': {
          paddingLeft: theme.spacing(1.5),
        },
        '.MuiOutlinedInput-root .MuiAutocomplete-input': {
          padding: '3px 0',
          '&:not(:focus)': {
            minWidth: 0,
          },
        },
        '.MuiInputLabel-formControl.MuiInputLabel-outlined': {
          maxWidth: `calc(100% - ${theme.spacing(6)})`,
          '&.MuiInputLabel-shrink': {
            maxWidth: `100%`,
          },
        },
        '.MuiAutocomplete-inputRoot': {
          gap: theme.spacing(0.75),
        },
      },
      listbox: {
        '& .MuiAutocomplete-option[aria-selected="true"]': {
          backgroundColor: theme.palette.transparent.gray10,
        },
        '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
          backgroundColor: theme.palette.transparent.gray10,
        },
      },
      loading: {
        fontSize: 14,
      },
      noOptions: {
        fontSize: 14,
      },
    },
  },

  MuiAlert: {
    styleOverrides: {
      root: {
        '& .MuiAlert-action': {
          paddingTop: 0,
        },
      },
    },
  },

  MuiDrawer: {
    styleOverrides: {
      root: {
        '& .MuiBackdrop-root': {
          backgroundColor: theme.palette.transparent.black24,
        },
      },
    },
  },

  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: theme.palette.shade.grey1,
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        '& .MuiSwitch-switchBase.Mui-checked': {
          color: theme.palette.backgroundExtension.white,
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.shade.grey6,
        },
      },
    },
  },
};

export const onMobile = theme.breakpoints.down('tablet');
export const onSmallDesktop = theme.breakpoints.between('desktop', 'large-desktop');

export function onSingleColumn(sx: SxProps): SxProps {
  return {
    [onMobile]: sx,
    [onSmallDesktop]: sx,
  };
}

export function gridColSpan(amount: number): SxProps {
  return {
    gridColumnEnd: `span ${amount}`,
  };
}

export function gridRowSpan(amount: number): SxProps {
  return {
    gridRowEnd: `span ${amount}`,
  };
}
