import { Box, Divider } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { LeadOptionChip } from 'components/contact/LeadAndContactPicker/LeadAndContactOptionItems';
import {
  BulkEmailRecipientTabPanels,
  BulkEmailRecipientTabs,
  RecipientTab,
} from 'components/emails/SendBulkEmailDialog/ComposeBulkEmailStep/BulkEmailRecipientTabs';
import { InternalComposeBulkEmailStepProps } from 'components/emails/SendBulkEmailDialog/ComposeBulkEmailStep/ComposeBulkEmailStep';
import { ComposeBulkEmailTemplateForm } from 'components/emails/SendBulkEmailDialog/ComposeBulkEmailStep/ComposeBulkEmailTemplateForm';
import { CreateFollowUpTaskToggle } from 'components/emails/SendBulkEmailDialog/ComposeBulkEmailStep/CreateFollowUpTaskToggle';
import { ShowPricesToggle } from 'components/emails/SendBulkEmailDialog/ComposeBulkEmailStep/ShowPricesToggle';
import { SendBulkEmailDialogHeader } from 'components/emails/SendBulkEmailDialog/SendBulkEmailDialogHeader';
import { EVChip } from 'components/general/Chips/EVChip/EVChip';
import { ContactTooltipChip } from 'components/general/Chips/TooltipChip/ContactTooltipChip';
import { ModalContainer, ModalHeaderMobile, ModalTitle, ModalTitleAction } from 'components/general/Modal';
import DirectoryIcon from 'components/icons/contact.svg?react';
import CrossIcon from 'components/icons/cross_naked.svg?react';
import MinimizeIcon from 'components/icons/minimize.svg?react';
import PencilIcon from 'components/icons/pencil.svg?react';
import { usePopover } from 'components/state/UIStateProvider';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'util/i18next';
import { ComposeBulkEmailFormData } from 'util/schemas/sendBulkEmailSchema';

export function ComposeBulkEmailStepMobile({
  possibleFromEmails,
  noEmailIntegration,
  onClose,
  onPreview,
}: InternalComposeBulkEmailStepProps) {
  const { t } = useTranslation(['communication']);
  const [activeTab, setActiveTab] = useState<RecipientTab>('to');
  const [isEditRecipients, setEditRecipients] = useState(false);
  const { control, watch } = useFormContext<ComposeBulkEmailFormData>();

  const title = watch('isExposeSending')
    ? t('communication:sendBulkEmailDialog.titleShortExpose')
    : t('communication:sendBulkEmailDialog.titleShort');
  const { isMinimized, setIsMinimized } = usePopover('emailBulk');

  return isEditRecipients ? (
    <>
      <ModalHeaderMobile>
        <Box sx={{ display: 'flex' }}>
          <ModalTitleAction
            onClick={() => {
              setIsMinimized(!isMinimized);
            }}
          >
            <MinimizeIcon />
          </ModalTitleAction>
          <ModalTitleAction onClick={onClose}>
            <CrossIcon />
          </ModalTitleAction>
        </Box>
        <ModalTitle>{t('communication:sendBulkEmailDialog.titleRecipients')}</ModalTitle>
        <Button variant="contained" color="secondary" onClick={() => setEditRecipients(false)}>
          {t('communication:sendBulkEmailDialog.recipients.doneButton')}
        </Button>
      </ModalHeaderMobile>
      <ModalContainer singleColumn>
        <Stack gap={3}>
          <BulkEmailRecipientTabs activeTab={activeTab} setActiveTab={setActiveTab} />
          <BulkEmailRecipientTabPanels activeTab={activeTab} />
        </Stack>
      </ModalContainer>
    </>
  ) : (
    <>
      <ModalHeaderMobile>
        <ModalTitleAction onClick={onClose}>
          <CrossIcon />
        </ModalTitleAction>
        <ModalTitle>{title}</ModalTitle>
        <Button variant="contained" color="secondary" onClick={onPreview} disabled={noEmailIntegration}>
          {t('communication:sendBulkEmailDialog.recipients.previewButtonWithoutCounter')}
        </Button>
      </ModalHeaderMobile>
      <Controller
        name="from"
        control={control}
        render={({ field }) => (
          <SendBulkEmailDialogHeader
            from={field.value}
            onChangeFrom={field.onChange}
            noEmailIntegration={noEmailIntegration}
            possibleFromEmails={possibleFromEmails}
          />
        )}
      />
      <ModalContainer singleColumn sx={{ opacity: noEmailIntegration ? 0.2 : undefined }}>
        <Stack gap={3}>
          <RecipientChips onEdit={() => setEditRecipients(true)} disabled={noEmailIntegration} />
          <Divider />
          <CreateFollowUpTaskToggle disabled={noEmailIntegration} />
          <ShowPricesToggle disabled={noEmailIntegration} />
          <ComposeBulkEmailTemplateForm disabled={noEmailIntegration} />
        </Stack>
      </ModalContainer>
    </>
  );
}

function RecipientChips({ onEdit, disabled }: { onEdit: () => void; disabled: boolean }) {
  const { watch } = useFormContext<ComposeBulkEmailFormData>();

  const firstContactOrLead = watch('to')[0];
  const numMore = watch('to').length - 1;

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <DirectoryIcon style={{ flex: 'none' }} />
      {firstContactOrLead &&
        (firstContactOrLead.type === 'CONTACT' ? (
          <ContactTooltipChip contactId={firstContactOrLead.id} sx={{ minWidth: 0 }} />
        ) : (
          <LeadOptionChip lead={firstContactOrLead} sx={{ minWidth: 0 }} />
        ))}
      {numMore > 0 && <EVChip label={`+${numMore}`} />}
      <IconButton onClick={onEdit} sx={{ marginLeft: 'auto' }} edge="end" disabled={disabled}>
        <PencilIcon />
      </IconButton>
    </Stack>
  );
}
